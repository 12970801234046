import React from 'react';
 import logo from "../../Assets/loading.gif";

const CircularProgressBar = ({ value }) => {
 

  return (
    <center>
      <img src={logo} alt="logo" width={50} height={50} />
    </center>
  );
};


export default CircularProgressBar;