import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { post, get } from "../../API/apiHelper";
import { ToastContainer, toast } from "react-toastify";
import SubscriptionTableRow from "./Widget/SubscriptionTableRow"
import { Modal, Button, Form } from 'react-bootstrap';
import { add_subscription, list_subscription, siteconfig } from "../../API/endpoints";
import CircularProgressBar from "../Component/Loading";
import subscriptionimage from "../../Assets/Images/subscription.png";
import '../../Assets/css/CloseButton.css';

function Subscription() {

  const navigate = useNavigate();
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    if (settings) {
      getfaqcatlist();
    }
  }, [settings]);

  const [res, setResponce] = useState({
    status: true,
    message: "Data retrieved successfully",
    data: [],
    pagination: {
      totalUsers: 1,
      currentPage: 2,
      totalPages: 1,
      pageSize: 10,

    },
  });
  const [currentPage, setcurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [subscription, setNewSubscription] = useState({ plane_name: '', amount: '', validation_days: '', features: [], type: '' });

  // Function to handle page change
  const handlePageChange = (page) => {
    setcurrentPage(page);
    getfaqcatlist(page);
  };
  const handleSearch = () => {
    setcurrentPage(1);
    getfaqcatlist(1);
  };

  const handleReset = () => {
    setcurrentPage(1);
    getfaqcatlist(1);
  };
  const [loading, SetLoading] = useState(true);


  // Fetch settings data on mount
  useEffect(() => {
    const fetchSettings = async () => {
      SetLoading(true);
      try {
        const res = await get(siteconfig, true);
        const response = await res.json();
        setSettings(response.data);
      } catch (error) {
        toast.error("Failed to fetch settings");
      } finally {
        SetLoading(false);
      }
    };

    fetchSettings();
  }, []);

  async function getfaqcatlist(page) {
    if (!settings) {
      return;
    }

    SetLoading(true);
    const response = await post(
      list_subscription,
      {
        page: page,
        limit: settings.par_page_limit,
      },
      true
    );
    const data = await response.json();

    if (response.status === 200) {
      setResponce(data);
      const totalPages = data.pagination.totalPages;
      const pagesArray = Array.from(
        { length: totalPages },
        (_, index) => index + 1
      );
      setPages(pagesArray);
      SetLoading(false);
    } else if (response.status === 403) {
      data.errors.forEach((error) => {
        toast.error(error.msg);
      });
    } else if (response.status === 401) {
      localStorage.setItem("authtoken", null);
      navigate("/");
      toast.error("Session Expire");
    } else {
      toast.error("Somthing Went Wrong");
    }
  }

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleShowCreate = () => {
    setShowCreateModal(true);
  };
  const handleCloseCreate = () => setShowCreateModal(false);
  const handleSave = async () => {
    console.log(subscription.features);
    await post(
      add_subscription,
      {
        plane_name: subscription.plane_name,
        amount: subscription.amount,
        type: subscription.type,
        validation_days: subscription.validation_days,
        feature : subscription.features.join(', '),
      }, 
      true,
    );

    toast.success('Subscription Add Successfully');
    handleCloseCreate();
    getfaqcatlist(currentPage);
  };



  return (
    <main id="content" role="main" class="main pointer-event">
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="content container-fluid">
        <div className="mb-4">
          <h6 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
            <img
              width="30"
              src={subscriptionimage}
              alt=""
            />
            Subscription list
            <span className="badge badge-soft-dark radius-50">{res.pagination.totallist}</span>
          </h6>
        </div>
        <div className="card">
          <div class="p-3 flex-grow-1">
            <div class="d-flex justify-content-between gap-3 flex-wrap align-items-center">

              <div class="align-items-center d-flex gap-3 justify-content-lg-end flex-wrap flex-lg-nowrap flex-grow-1">
                <Button variant="primary" size="sm" onClick={handleShowCreate}>
                  <i className="tio-add"> ADD NEW</i>
                </Button>
                <Modal show={showCreateModal} onHide={handleCloseCreate}>
                  <Modal.Header closeButton className="customModalHeader">
                    <Modal.Title>Add Subscription</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="formCategoryName">
                        <Form.Label>Plane Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter plane name"
                          value={subscription.label}
                          onChange={(e) => setNewSubscription({ ...subscription, plane_name: e.target.value })}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="formCategoryName">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter amount"
                          value={subscription.label}
                          onChange={(e) => setNewSubscription({ ...subscription, amount: e.target.value })}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="formCategoryName">
                        <Form.Label>Discount type</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter discount type"
                          value={subscription.label}
                          onChange={(e) => setNewSubscription({ ...subscription, type: e.target.value })}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="formCategoryName">
                        <Form.Label>Validation days</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter validation days"
                          value={subscription.label}
                          onChange={(e) => setNewSubscription({ ...subscription, validation_days: e.target.value })}
                          required
                        />
                      </Form.Group>
                      {/* 'Premium subscription: Able to send a message before you send a like tell them why you like them and stand apart from the rest (20 per day will be able to control from admin)',
                          'Get more likes',
                          'Unlimited likes',
                          'Get more Boost: Be a top profile in your area for 30 min (once a week)',
                          'No ads',
                          'Refine your search advance filters',
                          'Unlimited passport likes: Normal 10 likes per day' */}
                      <Form.Group controlId="formFeatureSelection">
                        <Form.Label>Features</Form.Label>
                        {[
                          // 'Initiate video/audio calls',
                          'msg_send_both_like',
                          'get_more_likes',
                          'unlimited_likes',
                          'be_top_profile_week1',
                          'no_ads',
                          'advance_filters',
                          'unlimited_passport_likes'
                        ].map((feature, index) => (
                          <Form.Check
                            key={index}
                            type="checkbox"
                            label={feature}
                            value={feature}
                            onChange={(e) => {
                              const { value, checked } = e.target;
                              setNewSubscription(prevSubscription => ({
                                ...prevSubscription,
                                features: checked
                                  ? [...prevSubscription.features, value] // Add selected feature
                                  : prevSubscription.features.filter(f => f !== value) // Remove unselected feature
                              }));
                            }}
                          />
                        ))}
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
          <div className="table-responsive datatable-custom">
            <table className="table table-hover table-bordered table-thead-bordered  table-align-middle card-table w-100">
              <thead className="thead-light thead-50 text-capitalize">
                <tr>
                  <th className="text-center">SL</th>
                  <th className="text-center">Plane name</th>
                  <th className="text-center">Amount</th>
                  <th className="text-center">Discount type</th>
                  <th className="text-center">Validation days</th>
                  <th className="text-center">Feature</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              {loading ? (<CircularProgressBar />) : (<tbody>
                {res.data.map((subscription, index) => (
                  <SubscriptionTableRow
                    key={index}
                    subscription={subscription}
                    onUpdate={getfaqcatlist} />
                ))}
              </tbody>)}
            </table>
          </div>
          <div class="table-responsive mt-4">
            <div className="px-4 d-flex justify-content-lg-end">
              <nav>
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      ‹
                    </button>
                  </li>
                  {pages.map((page) => (
                    <li
                      key={page}
                      className={`page-item ${page === currentPage ? "active" : ""
                        }`}
                      aria-current={page === currentPage ? "page" : null}
                    >
                      {page === currentPage ? (
                        <span className="page-link">{page}</span>
                      ) : (
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </button>
                      )}
                    </li>
                  ))}
                  <li
                    className={`page-item ${currentPage === res.pagination.totalPages
                      ? "disabled"
                      : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      ›
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          {/* <Pagination /> */}
        </div>
      </div>
    </main>
  );
}

export default Subscription;
