import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { post, get } from "../../API/apiHelper";
import { toast, ToastContainer } from "react-toastify";
import QuestionTableRow from "./Widget/QuestionTableRow";
import { Modal, Button, Form } from 'react-bootstrap';
import '../../Assets/css/CloseButton.css';
import { list_question, add_question, siteconfig } from "../../API/endpoints";
import CircularProgressBar from "../Component/Loading";
import questionImage from "../../Assets/Images/interest.png"; // Assuming there's an image for questions

function Question() {
  const navigate = useNavigate();

  const [res, setResponse] = useState({
    status: true,
    message: "Data retrieved successfully",
    data: [],
    pagination: {
      totalUsers: 1,
      currentPage: 1,
      totalPages: 1,
      pageSize: 10,
    },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [newQuestion, setNewQuestion] = useState({
    label: '',
    answer: '',
    options: [''],
  });
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const res = await get(siteconfig, true);
        const response = await res.json();
        setSettings(response.data);
      } catch (error) {
        toast.error("Failed to fetch settings");
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (settings) {
      getQuestionList(currentPage);
    }
  }, [settings, currentPage]);

  async function getQuestionList(page) {
    if (!settings) {
      return;
    }

    setLoading(true);
    try {
      const response = await post(
        list_question,
        {
          page: page,
          limit: settings.par_page_limit,
        },
        true
      );
      const data = await response.json();

      if (response.status === 200) {
        setResponse(data);
        const totalPages = data.pagination.totalPages;
        const pagesArray = Array.from(
          { length: totalPages },
          (_, index) => index + 1
        );
        setPages(pagesArray);
      } else if (response.status === 403) {
        data.errors.forEach((error) => {
          toast.error(error.msg);
        });
      } else if (response.status === 401) {
        localStorage.setItem("authtoken", null);
        navigate("/");
        toast.error("Session Expired");
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error fetching question list:", error);
    } finally {
      setLoading(false);
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleShowCreate = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreate = () => {
    setShowCreateModal(false);
    // Reset the form
    setNewQuestion({
      label: '',
      answer: '',
      options: [''],
    });
  };

  const handleSave = async () => {
    try {
      await post(
        add_question,
        {
          question: newQuestion.label,
          answer: newQuestion.answer,
          option: newQuestion.options,
        },
        true
      );
      toast.success('Added Successfully');
      handleCloseCreate();
      getQuestionList(currentPage); // Refetch data to show updated list
    } catch (error) {
      toast.error('Failed to add question');
    }
  };

  const handleOptionChange = (index, value) => {
    // Remove commas from the input value
    const sanitizedValue = value.replace(/,/g, '');

    // Update the option with the sanitized value
    const newOptions = [...newQuestion.options];
    newOptions[index] = sanitizedValue;

    // Update the state with the new options
    setNewQuestion({ ...newQuestion, options: newOptions });
  };

  const handleAddOption = () => {
    setNewQuestion({
      ...newQuestion,
      options: [...newQuestion.options, '']
    });
  };

  const handleRemoveOption = (index) => {
    const newOptions = newQuestion.options.filter((_, i) => i !== index);
    setNewQuestion({ ...newQuestion, options: newOptions });
  };

  return (
    <main id="content" role="main" className="main pointer-event">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="content container-fluid">
        <div className="mb-4">
          <h6 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
            <img
              width="30"
              src={questionImage}
              alt=""
            />
            Question list
            <span className="badge badge-soft-dark radius-50">{res.pagination.totalUsers}</span>
          </h6>
        </div>
        <div className="card">
          <div className="p-3 flex-grow-1">
            <div className="d-flex justify-content-between gap-3 flex-wrap align-items-center">
              <div className="align-items-center d-flex gap-3 justify-content-lg-end flex-wrap flex-lg-nowrap flex-grow-1">
                <Button variant="primary" size="sm" onClick={handleShowCreate}>
                  <i className="tio-add"> ADD NEW</i>
                </Button>
                <Modal show={showCreateModal} onHide={handleCloseCreate}>
                  <Modal.Header closeButton className="customModalHeader">
                    <Modal.Title>Add Question</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="formQuestion">
                        <Form.Label>Question</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter question"
                          value={newQuestion.label}
                          onChange={(e) => setNewQuestion({ ...newQuestion, label: e.target.value })}
                        />
                      </Form.Group>

                      <Form.Group controlId="formAnswer">
                        <Form.Label>Correct Answer</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter correct answer"
                          value={newQuestion.answer}
                          onChange={(e) => setNewQuestion({ ...newQuestion, answer: e.target.value })}
                        />
                      </Form.Group>

                      <Form.Group controlId="formOptions">
                        <Form.Label>Options</Form.Label>
                        {newQuestion.options.map((option, index) => (
                          <div className="d-flex mb-2" key={index}>
                            <Form.Control
                              type="text"
                              placeholder={`Option ${index + 1}`}
                              value={option}
                              onChange={(e) => handleOptionChange(index, e.target.value)}
                            />
                            <Button
                              variant="danger"
                              className="ms-2"
                              onClick={() => handleRemoveOption(index)}
                            >
                              Remove
                            </Button>
                          </div>
                        ))}
                        <Button variant="primary" onClick={handleAddOption}>
                          Add Option
                        </Button>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
          <div className="table-responsive datatable-custom">
            <table className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100">
              <thead className="thead-light thead-50 text-capitalize">
                <tr>
                  <th className="text-center">SL</th>
                  <th className="text-center">Question</th>
                  <th className="text-center">Right Answer</th>
                  <th className="text-center">Option</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              {loading ? (
                <CircularProgressBar />
              ) : (
                <tbody>
                  {res.data.map((question, index) => (
                    <QuestionTableRow
                      key={index}
                      Question={question}
                      onUpdate={getQuestionList}
                    />
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-between align-items-center">
              <Button
                variant="primary"
                onClick={handlePageChange.bind(null, currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <div className="pagination">
                {pages.map((page) => (
                  <Button
                    key={page}
                    variant={currentPage === page ? "primary" : "secondary"}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Button>
                ))}
              </div>
              <Button
                variant="primary"
                onClick={handlePageChange.bind(null, currentPage + 1)}
                disabled={currentPage === res.pagination.totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Question;
