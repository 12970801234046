import React, { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { IMAGE_BASE_URL } from "../../../API/endpoints";
import { post, del } from '../../../API/apiHelper';
import { interest_add, interest_delete } from '../../../API/endpoints';
import { toast } from 'react-toastify';
import '../../../Assets/css/CloseButton.css';

const InterestTableRow = ({ Interest, onUpdate }) => {
  const imageUrl = Interest.image ? `${IMAGE_BASE_URL}${Interest.image}` : null;


  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({ label: '', id: '', image: '' });

  const handleShowEdit = () => {
    setModalData({ label: Interest.label, id: Interest.id, image: imageUrl });
    setShowEditModal(true);
  };
  const handleCloseEdit = () => setShowEditModal(false);

  const handleShowDelete = () => {
    setModalData({ id: Interest.id });
    setShowDeleteModal(true);
  };
  const handleCloseDelete = () => setShowDeleteModal(false);

  const handleSave = async () => {
    try {
      await post(
        interest_add,
        {
          lable: modalData.label,
          id: modalData.id,
          folder: 'interest_images',
          image: modalData.image,
        },
        true
      );
      toast.success('Update Successfully');
      handleCloseEdit();
      onUpdate(); // Call the onUpdate callback to refresh data
    } catch (error) {
      toast.error('Update Failed');
    }
  };

  const handleDelete = async () => {
    try {
      await del(
        interest_delete,
        {
          id: modalData.id,
        },
        true
      );
      toast.success('Deleted Successfully');
      handleCloseDelete();
      onUpdate(); // Call the onUpdate callback to refresh data
    } catch (error) {
      toast.error('Delete Failed');
    }
  };

  return (
    <>
      <tr>
        <td className="text-center">{Interest.id}</td>
        <td className="text-center">{Interest.label || "-"}</td>
        <td className="text-center">
          {Interest.image ? (
            <img src={imageUrl} alt={Interest.label} style={{ width: "50px", height: "50px" }} />
          ) : (
            "-"
          )}
        </td>
        <td>
          <div className="d-flex justify-content-center gap-2">
            <Button variant="outline-info" size="sm" onClick={handleShowEdit}>
              <i className="tio-edit"></i>
            </Button>
            <Button variant="outline-danger" size="sm" onClick={handleShowDelete}>
              <i className="tio-delete"></i>
            </Button>
          </div>
        </td>
      </tr>

      <Modal show={showEditModal} onHide={handleCloseEdit}>
        <Modal.Header closeButton className="customModalHeader">
          <Modal.Title>Edit Interest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCategoryName">
              <Form.Label>Lable Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter lable name"
                value={modalData.label}
                onChange={(e) => setModalData({ ...modalData, label: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formCategoryImage">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                // accept=".svg" // Restrict to only SVG files
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file && file.type === 'image/svg+xml') {
                    setModalData({ ...modalData, image: file });
                  } else {
                    // Handle invalid file type (e.g., show an error message)
                    alert("Please select a valid SVG file.");
                    // Clear the input field
                    e.target.value = null;
                  }
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDelete}>
        <Modal.Header closeButton className="customModalHeader">
          <Modal.Title>Delete Interest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this interest?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InterestTableRow;
