import React, { useState, useEffect } from "react";
import "./dashboard.css";
import usersimage from "../../Assets/Images/customers.png";
import languageimage from "../../Assets/Images/language.png";
import interestimage from "../../Assets/Images/interest.png";
import supportimage from "../../Assets/Images/support.png";
import subscription from "../../Assets/Images/subscription.png";
import analytics from "../../Assets/Images/analytics.png";
import { post } from "../../API/apiHelper";
import { dashboard } from "../../API/endpoints";

function Login() {
  const [loading, SetLoading] = useState(true);
  const [data, setData] = useState([]);
  const [statisticsType, setStatisticsType] = useState("overall"); // State for filter

  useEffect(() => {
    const fetchData = async () => {
      try {
        SetLoading(true);
        const res = await post(dashboard,{ statistics_type: statisticsType });
        const response = await res.json();
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        SetLoading(false);
      }
    };
    fetchData();
  }, [statisticsType]);

  const handleStatisticsChange = (event) => {
    setStatisticsType(event.target.value); // Update filter criteria
  };

  return (
    <main id="content" role="main" className="main pointer-event">
      <div className="content container-fluid">
        <div className="page-header pb-0 mb-0 border-0">
          <div className="flex-between align-items-center">
            <div>
              <h1 className="page-header-title">Welcome Admin</h1>
              <p>Monitor your Admins.</p>
            </div>
          </div>
        </div>
        <div className="card mb-2 remove-card-shadow">
          <div className="card-body">
            <div className="row flex-between align-items-center g-2 mb-3">
              <div className="col-sm-6">
                <h4 className="d-flex align-items-center text-capitalize gap-10 mb-0">
                  <img src={analytics} alt="" width="30" />Datekar analytics
                </h4>
              </div>
              <div className="col-sm-6 d-flex justify-content-sm-end">
                <select
                  className="custom-select w-auto"
                  name="statistics_type"
                  id="statistics_type"
                  value={statisticsType}
                  onChange={handleStatisticsChange}
                >
                  <option value="overall">All</option>
                  <option value="today">Today's</option>
                  <option value="this_month">This Month's</option>
                </select>
              </div>
            </div>
            <div className="row g-2" id="order_stats">
              {/* users count */}
              <div className="col-sm-6 col-lg-3">
                <div className="business-analytics">
                  <h5 className="business-analytics__subtitle">Total Users</h5>
                  <h2 className="business-analytics__title">
                    {data.totalusers ? data.totalusers : "0"}
                  </h2>
                  <img src={usersimage} className="business-analytics__img" alt="" width="30" />
                </div>
              </div>
              {/* language count */}
              <div className="col-sm-6 col-lg-3">
                <div className="business-analytics">
                  <h5 className="business-analytics__subtitle">Total Language</h5>
                  <h2 className="business-analytics__title">
                    {data.languages ? data.languages : "0"}
                  </h2>
                  <img src={languageimage} className="business-analytics__img" alt="" width="30" />
                </div>
              </div>
              {/* interest count */}
              <div className="col-sm-6 col-lg-3">
                <div className="business-analytics">
                  <h5 className="business-analytics__subtitle">Total Interest</h5>
                  <h2 className="business-analytics__title">
                    {data.interests ? data.interests : "0"}
                  </h2>
                  <img src={interestimage} className="business-analytics__img" alt="" width="30" />
                </div>
              </div>
              {/* subscription */}
              <div className="col-sm-6 col-lg-3">
                <div className="business-analytics">
                  <h5 className="business-analytics__subtitle">Subscription</h5>
                  <h2 className="business-analytics__title">
                    {data.subscriptions ? data.subscriptions : "0"}
                  </h2>
                  <img src={subscription} className="business-analytics__img" alt="" width="30" />
                </div>
              </div>

              {/* support count */}
              <div className="col-sm-6 col-lg-3">
                <a className="order-stats order-stats_pending" href="/support">
                  <div className="order-stats__content">
                    <img width="20" src={supportimage} alt="" />
                    <h6 className="order-stats__subtitle">Pending</h6>
                  </div>
                  <span className="order-stats__title">
                    {data.pending_supports ? data.pending_supports : "0"}
                  </span>
                </a>
              </div>
              <div className="col-sm-6 col-lg-3">
                <a className="order-stats order-stats_confirmed" href="/support">
                  <div className="order-stats__content">
                    <img width="20" src={supportimage} alt="" />
                    <h6 className="order-stats__subtitle">In Process</h6>
                  </div>
                  <span className="order-stats__title">
                    {data.In_Process ? data.In_Process : "0"}
                  </span>
                </a>
              </div>
              <div className="col-sm-6 col-lg-3">
                <a className="order-stats order-stats_packaging" href="/support">
                  <div className="order-stats__content">
                    <img width="20" src={supportimage} alt="" />
                    <h6 className="order-stats__subtitle">Under Review</h6>
                  </div>
                  <span className="order-stats__title">
                    {data.Under_Review ? data.Under_Review : "0"}
                  </span>
                </a>
              </div>
              <div className="col-sm-6 col-lg-3">
                <a className="order-stats order-stats_out-for-delivery" href="/support">
                  <div className="order-stats__content">
                    <img width="20" src={supportimage} alt="" />
                    <h6 className="order-stats__subtitle">Resolved</h6>
                  </div>
                  <span className="order-stats__title">
                    {data.Resolved ? data.Resolved : "0"}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
