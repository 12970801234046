import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { post } from '../../../API/apiHelper';
import { add_subscription, del_subscription } from '../../../API/endpoints';
import { toast } from 'react-toastify';
import '../../../Assets/css/CloseButton.css';

const FaqcatTableRow = ({ subscription, onUpdate }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({
    subscription_id: '',
    plane_name: '',
    amount: '',
    validation_days: '',
    features: [], // Initialize as an array
    type: ''
  });

  const handleShowEdit = () => {
    setModalData({
      subscription_id: subscription.id,
      plane_name: subscription.plane_name,
      amount: subscription.amount,
      validation_days: subscription.validation_days,
      type: subscription.type,
      features: subscription.feature ? subscription.feature.split(', ').map(featu => featu.trim()) : []
    });
    setShowEditModal(true);
  };

  const handleCloseEdit = () => setShowEditModal(false);

  const handleSave = async () => {
    const featuresString = modalData.features.join(', ').trim();

    console.log('Saving modal data:', {
      ...modalData,
      feature: featuresString, // Add featuresString to the data being saved
    });

    await post(
      add_subscription,
      {
        id: modalData.subscription_id,
        plane_name: modalData.plane_name,
        amount: modalData.amount,
        type: modalData.type,
        validation_days: modalData.validation_days,
        feature: featuresString // Use the features array here
      },
      true
    );
    toast.success('Subscription updated successfully');
    handleCloseEdit();
    onUpdate();
  };

  const handleShowDelete = () => {
    setModalData({ subscription_id: subscription.id });
    setShowDeleteModal(true);
  };

  const handleCloseDelete = () => setShowDeleteModal(false);

  const handleDelete = async () => {
    await post(
      del_subscription,
      {
        id: modalData.subscription_id,
      },
      true
    );
    toast.success('Subscription deleted successfully');
    handleCloseDelete();
    onUpdate();
  };

  // This ensures the feature array is always defined
  const featureArray = subscription.feature ? subscription.feature.split(',').map(featu => featu.trim()) : [];
  const featureCount = featureArray.length;

  return (
    <>
      <tr>
        <td className="text-center">{subscription.id}</td>
        <td className="text-center">{subscription.plane_name || '-'}</td>
        <td className="text-center">{subscription.amount || '-'}</td>
        <td className="text-center">{subscription.type || '-'}</td>
        <td className="text-center">{subscription.validation_days || '-'}</td>
        <td className="text-center">
          {featureCount > 0 ? (
            featureArray.map((featu, index) => (
              <div key={index}>{index + 1}. {featu}</div>
            ))
          ) : (
            "-"
          )}
        </td>

        <td>
          <div className="d-flex justify-content-center gap-2">
            <Button variant="outline-info" size="sm" onClick={handleShowEdit}>
              <i className="tio-edit"></i>
            </Button>
            <Button variant="outline-danger" size="sm" onClick={handleShowDelete}>
              <i className="tio-delete"></i>
            </Button>
          </div>
        </td>
      </tr>

      {/* Modal for updating subscription */}
      <Modal show={showEditModal} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Update Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPlaneName">
              <Form.Label>Plane Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Plane Name"
                value={modalData.plane_name}
                onChange={(e) =>
                  setModalData({ ...modalData, plane_name: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Amount"
                value={modalData.amount}
                onChange={(e) =>
                  setModalData({ ...modalData, amount: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formType">
              <Form.Label>Discount Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Discount Type"
                value={modalData.type}
                onChange={(e) =>
                  setModalData({ ...modalData, type: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formValidationDays">
              <Form.Label>Validation Days</Form.Label>
              <Form.Control
                type="number"
                placeholder="Validation Days"
                value={modalData.validation_days}
                onChange={(e) =>
                  setModalData({ ...modalData, validation_days: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formFeatureSelection">
              <Form.Label>Features</Form.Label>
              {[
                'msg_send_both_like',
                'get_more_likes',
                'unlimited_likes',
                'be_top_profile_week1',
                'no_ads',
                'advance_filters',
                'unlimited_passport_likes'
              ].map((feature, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={feature}
                  value={feature}
                  checked={modalData.features ? modalData.features.includes(feature) : false} // Check if features are defined
                  onChange={(e) => {
                    const { value, checked } = e.target;

                    setModalData(prevModalData => ({
                      ...prevModalData,
                      features: checked
                        ? [...prevModalData.features, value] // Add selected feature
                        : prevModalData.features.filter(f => f !== value) // Remove unselected feature
                    }));
                  }}
                />
              ))}
            </Form.Group>

            <Form.Control
              type="hidden"
              placeholder="subscription id"
              value={modalData.subscription_id}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for delete confirmation */}
      <Modal show={showDeleteModal} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this subscription?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FaqcatTableRow;
