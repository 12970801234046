
export const SERVER_URL = "https://dating.readytouse.in/";
// export const SERVER_URL = "http://192.168.31.24:4200/";
export const API_BASE_URL = SERVER_URL + 'api/';
export const IMAGE_BASE_URL = SERVER_URL + 'public/';

export const login = "auth/adminlogin";
export const dashboard = "home/";
export const admin_profile = "auth/admin_profile";
export const admin_profile_update = "auth/admin_profile_update";
export const admin_password_update = "auth/admin_password_update";

export const userdetails = "auth/";
export const users_list = "user/users_list";
export const update_user_status = "user/update_user_status";
export const user_list_other = "user/user_list_other";

export const support_list = "support/list";
export const support_update = "support/update";

export const interest_add = "interest/add";
export const interest_list = "interest/list";
export const interest_delete = "interest/delete";

export const report_list = "report/list";

export const add_faq_category = "faq/add";
export const faq_category_list = "faq/list";
export const faq_category_delete = "faq/delete";

export const faq_add = "faq/add_faq";
export const faq_list = "faq/faq_list";
export const faq_delete = "faq/faq_delet";

export const language_add = "language/add";
export const language_list = "language/list";
export const language_delete = "language/delete";

export const settings = "setting/settings";
export const siteconfig = "setting/settings";
export const updateSettings = "setting/updateSettings";

export const add_subscription = "subscription/add";
export const list_subscription = "subscription/list";
export const del_subscription = "subscription/del";
export const purchage_subscription_list = "subscription/purchage_subscription_list";

export const add_question = "question/add";
export const list_question = "question/list";
export const del_question = "question/delete";

export const send_notification = "comman/send_notification";
