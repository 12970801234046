import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { post, get } from "../../API/apiHelper";
import { toast, ToastContainer } from "react-toastify";
import InterestTableRow from "./Widget/InterestTableRow";
import { Modal, Button, Form } from 'react-bootstrap';
import '../../Assets/css/CloseButton.css';
import { interest_list, interest_add, siteconfig } from "../../API/endpoints";
import CircularProgressBar from "../Component/Loading";
import interestimage from "../../Assets/Images/interest.png";

function Interest() {
  const navigate = useNavigate();

  const [res, setResponse] = useState({
    status: true,
    message: "Data retrieved successfully",
    data: [],
    pagination: {
      totalUsers: 1,
      currentPage: 2,
      totalPages: 1,
      pageSize: 10,
    },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [newInterest, setNewInterest] = useState({ label: '', image: '' });
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);

  // Fetch settings data on mount
  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const res = await get(siteconfig, true);
        const response = await res.json();
        setSettings(response.data);
      } catch (error) {
        toast.error("Failed to fetch settings");
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  // Fetch interest list
  useEffect(() => {
    if (settings) {
      getinterestlist(currentPage);
    }
  }, [settings, currentPage]);

  async function getinterestlist(page) {
    if (!settings) {
      return;
    }

    setLoading(true);
    try {
      const response = await post(
        interest_list,
        {
          page: page,
          limit: settings.par_page_limit, // Use the limit from settings
        },
        true
      );
      const data = await response.json();

      if (response.status === 200) {
        setResponse(data);
        const totalPages = data.pagination.totalPages;
        const pagesArray = Array.from(
          { length: totalPages },
          (_, index) => index + 1
        );
        setPages(pagesArray);
      } else if (response.status === 403) {
        data.errors.forEach((error) => {
          toast.error(error.msg);
        });
      } else if (response.status === 401) {
        localStorage.setItem("authtoken", null);
        navigate("/");
        toast.error("Session Expired");
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error fetching interest list:", error);
    } finally {
      setLoading(false);
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    getinterestlist(1);
  };

  const handleReset = () => {
    setCurrentPage(1);
    getinterestlist(1);
  };

  const handleShowCreate = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreate = () => setShowCreateModal(false);

  const handleSave = async () => {
    try {
      await post(
        interest_add,
        {
          lable: newInterest.label,
          folder: 'interest_images',
          image: newInterest.image,
        },
        true,
      );
      toast.success('Added Successfully');
      handleCloseCreate();
      getinterestlist(currentPage); // Refetch data to show updated list
    } catch (error) {
      toast.error('Failed to add interest');
    }
  };

  return (
    <main id="content" role="main" className="main pointer-event">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="content container-fluid">
        <div className="mb-4">
          <h6 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
            <img
              width="30"
              src={interestimage}
              alt=""
            />
            Interest list
            <span className="badge badge-soft-dark radius-50">{res.pagination.totalUsers}</span>
          </h6>
        </div>
        <div className="card">
          <div className="p-3 flex-grow-1">
            <div className="d-flex justify-content-between gap-3 flex-wrap align-items-center">
              <div className="align-items-center d-flex gap-3 justify-content-lg-end flex-wrap flex-lg-nowrap flex-grow-1">
                <Button variant="primary" size="sm" onClick={handleShowCreate}>
                  <i className="tio-add"> ADD NEW</i>
                </Button>
                <Modal show={showCreateModal} onHide={handleCloseCreate}>
                  <Modal.Header closeButton className="customModalHeader">
                    <Modal.Title>Add Interest</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="formCategoryName">
                        <Form.Label>Label Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter label name"
                          value={newInterest.label}
                          onChange={(e) => setNewInterest({ ...newInterest, label: e.target.value })}
                        />
                      </Form.Group>
                      <Form.Group controlId="formCategoryImage">
                        <Form.Label>Image</Form.Label>
                        <Form.Control
                          type="file"
                          accept=".svg" // Restrict to only SVG files
                          onChange={(e) => setNewInterest({ ...newInterest, image: e.target.files[0] })}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
          <div className="table-responsive datatable-custom">
            <table className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100">
              <thead className="thead-light thead-50 text-capitalize">
                <tr>
                  <th className="text-center">SL</th>
                  <th className="text-center">Label</th>
                  <th className="text-center">Image</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              {loading ? (
                <CircularProgressBar />
              ) : (
                <tbody>
                  {res.data.map((interest, index) => (
                    <InterestTableRow
                      key={index}
                      Interest={interest}
                      onUpdate={getinterestlist}
                    />
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <div className="table-responsive mt-4">
            <div className="px-4 d-flex justify-content-lg-end">
              <nav>
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      ‹
                    </button>
                  </li>
                  {pages.map((page) => (
                    <li
                      key={page}
                      className={`page-item ${page === currentPage ? "active" : ""}`}
                      aria-current={page === currentPage ? "page" : null}
                    >
                      {page === currentPage ? (
                        <span className="page-link">{page}</span>
                      ) : (
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </button>
                      )}
                    </li>
                  ))}
                  <li
                    className={`page-item ${currentPage === res.pagination.totalPages ? "disabled" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      ›
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Interest;
